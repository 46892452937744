import React, {useState, useEffect, useRef } from "react";
import {Columns, Content, Figure, Modal, Title, SubTitle, FormInput, FormSelect, Divider, Label, Button, Field, Container, Footer, Section, HeroBody, Input} from "botero"
import { faClock, faUser , faPrescription } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Api from "../services/FormSubmissionApi";
import cuid from "cuid";
import { useHistory } from "react-router-dom";
import "../App.scss";
import moment from 'moment';
import { toast } from 'bulma-toast'
import { SITE_KEY } from "../config";

const AriseDoctorFormNonFmi = () => {
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const [patient, setPatient] = useState({name:params.get("patientName") ? params.get("patientName") : "",error:""});
    const [treatmentIndicationSubTitle, setTreatmentIndicationSubTitle] = useState('');
    const [physicianEmail, setPhysicianEmail] = useState({value: params.get("doctorEmail") ? params.get("doctorEmail") : "" ,error:""});
    const [phoneNumber, setPhoneNumber] = useState({value: params.get("phoneNumber") ? params.get("phoneNumber") : "+65 ",error:""});
    const [nric, setNric] = useState({value:params.get("patientId") ? params.get("patientId") : "",error:""});
    const [treatment, setTreatment] = useState({value:"",error:""});
    const [isHemophilia, setHemophilia] = useState(false);
    const [treatmentIndication, setTreatmentIndication] = useState({value:"",error:""});

    const [physicianName, setPhysicianName] = useState({value:params.get("doctorName") ? params.get("doctorName") : "" ,error:""});
    const [hospitalName, setHospitalName] = useState({value:params.get("hospitalName") ? params.get("hospitalName") : "" ,error:""});

    const [treamtmentDataMap, setTreatmentDataMap] = useState([]);

    const [isSubsidized, setSubsidized] = useState(true);
    const [hasConsented, setConsent] = useState(true);

    const [hasHemophiliaA, setHemophiliaA] = useState(false);
    const [isMAFEligble, setMAFEleigible] = useState(false);
    const [subsidyLevel, setSubsidyLevel] = useState({value:"",error:""});
    const [dosingRegimen, setDosingRegimen] = useState({value:"",error:""});

    const [isNotified, setNotified] = useState(true);
    const [hasNoPhone, setHasNoPhone] = useState(false);
    const submissionId = cuid(); 
    const topRef = useRef(null);
    const midRef = useRef(null);
    const [isModalOpen,setModalOpen] = useState(false);
    const [isLoading,setLoading] = useState(false);

    Api.init();

    useEffect(() => {
        document.title = "Arise Enrollment Form"
     }, []);

     const renderModal = () => {
         return (
         <Modal title = "Confirm Details" isLoading={isLoading} state={isModalOpen?"is-active":""} onCancelClick={(e)=>{setModalOpen(false);setLoading(false);scrollToTop();}} 
         onSubmitClick={()=>{
             window.grecaptcha.ready(() => {
              window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                  submitForm(token);
              });
            });
          }}>
         <SubTitle className="is-4" > <strong> Patient Details </strong></SubTitle>
          <ul >
              <Content element="li"> <strong> NRIC/FIN: </strong>{nric.value}</Content>
              <Content element="li"> <strong> Patient Name: </strong> {patient.name}</Content>
              <Content element="li" visibility={ hasNoPhone ? "hidden" : ""}> <strong> Patient Contact: </strong> {phoneNumber.value}</Content>
          </ul>
          <br/>
          <SubTitle className="is-4"> <strong> Treatment Info </strong></SubTitle>
          <ul>
              <Content element="li"> <strong> Treatment: </strong> {treatment.value}</Content>
              <Content element="li"> <strong> Treatment Indication: </strong> {treatmentIndication.value}</Content>
              <Content element="li"> <strong> Is Patient Subsidized: </strong>{isSubsidized? "Yes": "No"}</Content>
              <Content element="li" visibility={treatment.value === "Hemlibra®" ? "" : "hidden"}> <strong> Hemophilia A without inhibitors: </strong> {hasHemophiliaA ? "Yes" : "No"}</Content>
              <Content element="li" visibility={treatment.value === "Hemlibra®" ? "" : "hidden"}> <strong> Is MAF Eligible: </strong>{isMAFEligble ? "Yes" : "No"}</Content>
              <Content element="li" visibility={isMAFEligble ? "" : "hidden"}> <strong> Subsidy Level: </strong>{ subsidyLevel.value === "" || subsidyLevel.value === "Select subsidy level" ? ""  : subsidyLevel.value}</Content>
              <Content element="li" visibility={treatment.value === "Hemlibra®" ? "" : "hidden"}> <strong> Dosing Regimen: </strong>{dosingRegimen.value}</Content>

          </ul>
          <br/>
          <SubTitle className="is-4">  <strong> Physician Details </strong></SubTitle>
          <ul>
              <Content element="li"> <strong> Physician Name: </strong>{physicianName.value}</Content>
              <Content element="li"> <strong> Physician Email: </strong> {physicianEmail.value}</Content>
              <Content element="li"> <strong> Hospital: </strong>{hospitalName.value}</Content>
          </ul>
      </Modal>)
     }


    useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
   
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
   
      if (isScriptExist && callback) callback();
    }
   
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

     const submitForm = (token) => {
        setLoading(true);
        var formData = {};
        formData.arise_doctor_submission_id = submissionId;
        formData["g-recaptcha-response"]= token;
        formData.form_id = "arise-doctor-form";
        formData.form_submission_date = new Date().toISOString();
        formData.form_response = {
          patient_id: nric.value,
          patient_name: patient.name,
          patient_contact: phoneNumber.value,
          treatment: treatment.value,
          is_subsidized: isSubsidized,
          hemophilia: hasHemophiliaA ? "YES" : "NO",
          maf: isMAFEligble ? "YES" : "NO",
          maf_subsidy: isMAFEligble ? "Subsidy : " + subsidyLevel.value: "",
          dosing_regimen: dosingRegimen.value,
          indication: treatmentIndication.value === 'Select Indication'? "" : treatmentIndication.value,
          doctor_name: physicianName.value,
          doctor_email: physicianEmail.value,
          hospital_name: hospitalName.value,
          consent: hasConsented,
          has_mobile_phone: !hasNoPhone,
          notify_patient_enrollment: isNotified,
          doctor_date: moment().format('LLLL')
        };
        Api.submitForm(formData).then((response)=>{
          setLoading(false);
            if(response.status === 204){
              history.push("/thank_you_doctor_arise");
            }else{
                setModalOpen(false);
            }
        }).catch((e)=> {
            setLoading(false);
            setModalOpen(false);
            toast({
                message: '<h1>Something went wrong, Please try again later</h1>',
                type: 'is-danger',
                dismissible: true,
                position: "top-center",
                closeOnClick: true
              })
        });
     }

    const validateForm = () => {
        var error = false
        if(patient.name.length === 0){
            setPatient({error:"Patient Name is required",name:patient.name});
            error = true;
            scrollToTop();
        }if(!hasNoPhone && phoneNumber.value.length < 5){
            setPhoneNumber({error:"Patient Mobile is required OR Select checkbox if Patient has no mobile",value:"+65 "});
            error = true;
            scrollToTop();
        }if(nric.value.length === 0){
            setNric({error:"NRIC/FIN is required",value:""});
            error = true;
            scrollToTop();
        }if(physicianName.value.length === 0){
            setPhysicianName({error:"Physician name is required",value:""});
            error = true;
        }if(hospitalName.length === 0){
            setHospitalName({error:"Hospital name is required",value:""});
            error = true;
        }if(physicianEmail.value.length === 0){
            setPhysicianEmail({error:"Physician email is required",value:""});
            error = true;
        }if(treatment.value==="Hemlibra®" && isMAFEligble && (subsidyLevel.value === "" || subsidyLevel.value === "Select subsidy level")){
            setSubsidyLevel({error:"Subsidy is required",value:""})
            error = true;
        }else if(treatment.value!== "Hemlibra®"){
            setSubsidyLevel({error:"",value:""})
        }
        if(treatment.value==="Hemlibra®" && (dosingRegimen.value.length === 0 || dosingRegimen.value === 'Select dosing regimen')){
            setDosingRegimen({error:"Dosing regimen is required",value:""});
            error = true;
            scrollToMid();
        }
        if(treatment.value.length === 0 || treatment.value === 'Select Treatment'){
            setTreatment({error:"Treatment is required",value:""});
            error = true;
            scrollToMid();
        }else if(treatment.value !== "Hemlibra®" && (treatmentIndication.value.length === 0 || treatmentIndication.value === 'Select Indication')){
            setTreatmentIndication({error:"Indication is required",value:""});
            error = true;
            scrollToMid();
        }
        return error;
    }

    const scrollToTop = () => { topRef.current.scrollIntoView()};

    const scrollToMid = () => { midRef.current.scrollIntoView()};

    useEffect(()=>{
        setHemophilia(false);
        if(treatment.value === 'Avastin®'){
           setTreatmentDataMap(["Select Indication","Breast Ca 1L","Cervical Ca 1L","Cervical Ca 2L+","Colorectal Ca 1L","Colorectal Ca 2L+","Glioblastoma 2L+","NSCLC 1L","Ovarian Ca 1L","Ovarian Ca 2L+","Renal Cell Ca 1L"])
           setTreatmentIndicationSubTitle('1L = 1st line treatment; 2L+ = 2nd line treatment and beyond');
        }else if(treatment.value === 'Alecensa®'){
            setTreatmentDataMap(["Select Indication","NSCLC ALK+ 1L","NSCLC ALK+ 2L"]);
            setTreatmentIndicationSubTitle('1L = 1st line treatment; 2L = 2nd line treatment');
        }else if(treatment.value === 'Gazyva®'){
            setTreatmentDataMap(["Select Indication","FL 1L","FL 2L+","CLL 1L"]);
            setTreatmentIndicationSubTitle('1L = 1st line treatment; 2L+ = 2nd line treatment and beyond');
        }else if(treatment.value === 'Hemlibra®'){
            setTreatmentDataMap([]);
            setHemophilia(true);
        }else if(treatment.value === 'Herceptin® SC'){
            setTreatmentDataMap(["Select Indication","Breast Ca - Adjuvant","Breast Ca - Metastatic","Breast Ca - Neoadjuvant",]);
            setTreatmentIndicationSubTitle('');
        }else if(treatment.value === 'Kadcyla®'){
            setTreatmentDataMap(["Select Indication","Breast Ca - Adjuvant","Breast Ca - Metastatic"]);
            setTreatmentIndicationSubTitle('');
        }else if(treatment.value === 'MabThera® SC'){
            setTreatmentDataMap(["Select Indication","DLBCL","FL 1L+"]);
            setTreatmentIndicationSubTitle('1L+ = 1st line treatment and beyond');
        }else if(treatment.value === 'Tecentriq®'){
            setTreatmentDataMap(["Select Indication","ES-SCLC 1L","Metastatic TNBC 1L","NSCLC 1L","NSCLC 2L"]);
            setTreatmentIndicationSubTitle('1L = 1st line treatment; 2L = 2nd line treatment');
        }else if(treatment.value === 'Tecentriq® + Avastin®'){
            setTreatmentDataMap(["Select Indication","NSCLC 1L","NSCLC *EGFR/ALK+","Unresectable HCC 1L"]);
            setTreatmentIndicationSubTitle('1L = 1st line treatment; *EGFR/ALK+ patients with disease progression on approved therapy');
        }else if(treatment.value === 'Perjeta® + Herceptin®'){
            setTreatmentDataMap(["Select Indication","Breast Ca - Adjuvant","Breast Ca - Metastatic 1L","Breast Ca - Neoadjuvant"]);
            setTreatmentIndicationSubTitle('1L = 1st line treatment');
        }else if(treatment.value === 'Polivy® + MabThera® IV'){
            setTreatmentDataMap(["Select Indication","DLBCL 2L","DLBCL 3L+"]);
            setTreatmentIndicationSubTitle(' 2L = 2nd line treatment; 3L+ = 3rd line treatment and beyond');
        }else{
            setTreatmentDataMap([]);
        }
    },[treatment]);

    return (
    <Columns> 
        <Content element="div" className="is-two-fifths hero is-link">
           <Section className="sticky">
               <HeroBody className="is-paddingless"> 
                   <Figure src="https://i.imgur.com/IlOJRNc.png"></Figure>
                    <Title className="is-size-3 is-spaced has-text-white-ter has-text-centered">
                        ARISE Physician Enrollment Form
                    </Title>
                    <br/>
                    <SubTitle className="has-text-centered is-5 has-text-white-ter">
                        <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faClock} style={{marginRight:10}} />
                        </Content> 
                        <b>5 min</b> estimated time to complete
                </SubTitle>
                <SubTitle className="is-5 has-text-white-ter"><br/>
                    <SubTitle className="is-5 has-text-weight-bold">Instructions</SubTitle>
                    <SubTitle className="is-6 has-text-white-ter">
                        <u>Eligibility criteria:</u> <br/><br/>
                        - ARISE is open to patients who are receiving treatment in a Singapore public healthcare institution<br/><br/>
                        - Patients are prescribed with selected Roche treatment/diagnostic listed below for indications approved by Singapore Health Sciences Authority
                    </SubTitle>
                </SubTitle>                    
               </HeroBody>
           </Section>
        </Content>
        <Content element="div" className="is-paddingless">
           <Section >
           <HeroBody className="is-paddingless"> 
            <Container className="is-fluid">
              <Divider> 
                  <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faUser} style={{marginRight:10}} />
                   </Content>  Patient Details 
              </Divider>
              <FormInput type="text" className="input" size="medium" placeholder="Enter Patient Name" state={patient.error.length>0 ? "danger" : ""} errorText={patient.error} labelText="Patient Name" value={patient.name} onChange={(e) => {setPatient({name:e.target.value,error:""})}}/>
              <FormInput type="text" className="input" size="medium" maxlength="4" placeholder="Enter Last 4 digits" labelText="Patient Partial NRIC/FIN(Last 4 digits)" state={nric.error.length>0 ? "danger" : ""} errorText={nric.error} value={nric.value} onChange={(e) => setNric({value:e.target.value.toUpperCase(),error:""})}/>
              <br/>
              <div ref={topRef}/>
              <Label size="medium"> Is the named patient above a subsidized patient? </Label>
              <Content element="div" className="field is-grouped">
                  <Button color="primary" buttonStyle={isSubsidized? "" : "outlined"} style={{marginRight:10}} onClick={(e)=>{if(!isSubsidized) setSubsidized(true)}}>
                      YES
                  </Button>
                  <Button color={isSubsidized? "" : "secondary"} buttonStyle={isSubsidized? "" : "outlined"} background={isSubsidized? "" : "grey-light"} onClick={(e)=>{if(isSubsidized) setSubsidized(false)}}>
                      NO
                  </Button>
              </Content>

              <FormInput iconPosition="left" icon="phone" type="tel" className="input" disabled={hasNoPhone} size="medium" placeholder="+65" labelText="Patient/Caregiver Mobile" value={phoneNumber.value} state={phoneNumber.error.length>0 ? "danger" : ""} errorText={phoneNumber.error} onChange={(e) => setPhoneNumber({value:e.target.value,error:""})}/>
              <Content element="p" type="checkbox" >
                <Input type="checkbox" style={{marginRight:10}} onChange={(e)=>{setHasNoPhone(!hasNoPhone); if(!hasNoPhone){setPhoneNumber({value:"+65 ",error:""})}}}/>
                 Select this if your patient or their caregiver <strong>DOES NOT </strong>have a mobile phone and needs a hard copy to sign 
              </Content>
              <br/>
            
              <div ref={midRef}/>
              <Divider> 
                  <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faPrescription} style={{marginRight:10}} />
                   </Content> Treatment Info 
              </Divider>


              <FormSelect state={treatment.error.length>0 ? "danger" : ""} errorText={treatment.error} labelText="Treatment" size="medium" onChange={(e) => {setTreatment({value:e.target.value,error:""}); setTreatmentIndication({value:"Select Indication",error:""})}}>
                    <option className="selected"> Select Treatment</option>
                    <option>Alecensa®</option>
                    <option>Avastin®</option>
                    <option>Gazyva®</option>
                    <option>Hemlibra®</option>
                    <option>Herceptin® SC</option>
                    <option>Kadcyla®</option>
                    <option>MabThera® SC</option>
                    <option>Tecentriq®</option>
                    <option>Tecentriq® + Avastin®</option>
                    <option>Perjeta® + Herceptin®</option>
                    <option>Polivy® + MabThera® IV</option>
              </FormSelect>

              <FormSelect state={treatmentIndication.error.length>0 ? "danger" : ""} errorText={treatmentIndication.error} labelText="Treatment Indication" size="medium" visibility={treamtmentDataMap.length>0 ? "": "hidden"} onChange={((e) => setTreatmentIndication({value:e.target.value,error:""}))} >
                    {treamtmentDataMap.map((treatment)=> <option>{treatment}</option>)}
              </FormSelect>
              <Content element="div" visibility={treamtmentDataMap.length>0 ? "": "hidden"}  className="subtitle is-7"> {treatmentIndicationSubTitle} </Content>

              <Content element="div" visibility={isHemophilia? "" : "hidden"}>
              <br/>
              <Label> Does this patient have Hemophilia A without inhibitors? </Label>
              <Content element="div" className="field is-grouped">
                  <Button color="primary" buttonStyle={hasHemophiliaA? "" : "outlined"} style={{marginRight:10}} onClick={(e)=>{if(!hasHemophiliaA) setHemophiliaA(true)}}>
                      YES
                  </Button>
                  <Button color={hasHemophiliaA? "" : "secondary"} buttonStyle={hasHemophiliaA? "" : "outlined"} background={hasHemophiliaA? "" : "grey-light"} onClick={(e)=>{if(hasHemophiliaA) setHemophiliaA(false)}}>
                      NO
                  </Button>
              </Content>
              <br/>

              <Label> Is this patient MAF+ eligible? </Label>
              <Content element="div" className="field is-grouped">
              <Button color="primary" buttonStyle={isMAFEligble? "" : "outlined"} style={{marginRight:10}} onClick={(e)=>{if(!isMAFEligble) setMAFEleigible(true)}}>
                      YES
                  </Button>
                  <Button color={isMAFEligble? "" : "secondary"} buttonStyle={isMAFEligble? "" : "outlined"} background={isMAFEligble? "" : "grey-light"} onClick={(e)=>{if(isMAFEligble) setMAFEleigible(false)}}>
                      NO
                  </Button>
              </Content>

              <FormSelect visibility={isMAFEligble? "" : "hidden"} state={subsidyLevel.error.length>0 ? "danger" : ""} errorText={subsidyLevel.error} labelText="MAF+ Subsidy Level" size="medium" onChange={((e) => setSubsidyLevel({value:e.target.value,error:""}))}>
                    <option className="selected"> Select subsidy level</option>
                    <option>50%</option>
                    <option>75%</option>
                    <option>Others</option>
              </FormSelect>

              <FormSelect state={dosingRegimen.error.length>0 ? "danger" : ""} errorText={dosingRegimen.error} labelText="Dosing Regimen" size="medium" onChange={(e) => setDosingRegimen({value:e.target.value,error:""})}>
                    <option className="selected"> Select dosing regimen</option>
                    <option>1 weekly</option>
                    <option>2 weekly</option>
                    <option>4 weekly</option>
              </FormSelect>
              </Content>

              <br/>
              <Divider> 
                  <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faUser} style={{marginRight:10}} />
                   </Content> Physician Details
              </Divider>

              <FormInput type="text" className="input" style={{color:"black"}} size="medium" disabled={true} placeholder="Enter Physician here" labelText="Physician Name" value={physicianName.value} state={physicianName.error.length>0 ? "danger" : ""} errorText={physicianName.error}  onChange={(e) => setPhysicianName({name:e.target.value,error:""})}/>
              <FormInput type="text" className="input" style={{color:"black"}} size="medium" disabled={true} placeholder="Enter Hospital Name" labelText="Hospital" value={hospitalName.value} state={hospitalName.error.length>0 ? "danger" : ""} errorText={hospitalName.error} onChange={(e) => setHospitalName({name:e.target.value,error:""})}/>
              <FormInput iconPosition="left" icon="email" style={{color:"black"}} type="email" className="input" size="medium" disabled={true} placeholder="Enter Email" labelText="Physician Email" value={physicianEmail.value} state={physicianEmail.error.length>0 ? "danger" : ""} errorText={physicianEmail.error} onChange={(e) => setPhysicianEmail({name:e.target.value,error:""})}/>
              <br/>

              <Content element="p">I give consent to be contacted by the Roche Local Safety Unit to obtain additional information on reported adverse event(s) experienced by the patient enrolled in ARiSE programme under my care.</Content>

              <br/>
              <Content element="div" className="field is-grouped">
                  <Button color="primary" buttonStyle={hasConsented? "" : "outlined"} style={{marginRight:10}} onClick={(e)=>{if(!hasConsented) setConsent(true)}}>
                      YES
                  </Button>
                  <Button color={hasConsented? "" : "secondary"} buttonStyle={hasConsented? "" : "outlined"} background={hasConsented? "" : "grey-light"} onClick={(e)=>{if(hasConsented) setConsent(false)}}>
                      NO
                  </Button>
              </Content>

              <br/>

              <Content element="p" classname="" type="checkbox" >
                <Input type="checkbox" style={{marginRight:10}} defaultChecked onChange={(()=>{setNotified(!isNotified)})}/>
                I <strong>WISH</strong> to be notified of the outcome of the patient's enrollment application
              </Content>
              
              <Field>
                  <p>I understand, accept and acknowledge that this is a digital enrolment form and my wet signature is not required. I understand, accept and acknowledge that by submitting this form, I give my consent to BioQuest Solutions (hereinafter ”BQ”), 5 Health Pte Ltd (hereinafter “Bot MD”), and Roche Singapore Pte Ltd and its affiliates (hereinafter “Roche”) to collect, use, store and disclose my personal data for any purpose of contacting me for the ARiSE programme. I give my consent to BQ, Bot MD and Roche to disclose this information to third parties assigned to conduct audits of the programme.</p>
              </Field>
              <br/>
              <Button className="is-link is-fullwidth" size="large" type="submit" onClick={(e)=>{
                  if(!validateForm()){                
                    setModalOpen(true);
                }
              }}>
                  Submit
             </Button>
            </Container>
            </HeroBody>
           </Section>
           <Footer className="has-text-centered"> 
               <Container>
                   <Content element="p" >
                        <Content element="strong" className="has-text-weight-semibold">
                            <a href="https://www.roche.com.sg">Roche Singapore</a>
                        </Content>
                   </Content>
                   <Content element="p" className="">
                        <Content element="small">
                            Form powered by <a href="https://www.botmd.io">Bot MD</a>
                         </Content>
                   </Content>
               
                <Figure src="https://i.imgur.com/l2QQZuE.png"></Figure>
               </Container>
           </Footer>
           {renderModal()}
        </Content>
    </Columns>
    );
}

export default AriseDoctorFormNonFmi;
