import {Columns, Content, Figure, Title, Modal, SubTitle, FormInput, Divider, Button, Field, Container, Footer, Section, HeroBody} from "botero"
import React, {useState, useEffect} from "react";
import { faClock, faUser } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../App.scss";
import Api from "../services/FormSubmissionApi";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { toast } from 'bulma-toast'
import { SITE_KEY } from "../config";

const PSPPatientForm = () => {
    const params = new URLSearchParams(window.location.search);
    const [nric, setNric] = useState({value:"",error:""});
    const history = useHistory();
    const pspDoctorSubmissionId = params.get("doctorSubmissionId");
    const [isModalOpen,setModalOpen] = useState(false);
    const [isLoading,setLoading] = useState(false);

    Api.init();


    const validateForm = () => {
        var error = false
        if(nric.value.length === 0){
            setNric({error:"NRIC/FIN/Passport is required",value:nric.value});
            error = true;
        }else if(nric.value.length < 4){
            setNric({error:"NRIC/FIN requires the last 4 digits", value:nric.value});
            error = true;
        }
        return error;
    }


    useEffect(() => {
        document.title = "PSP Patient Form"
     }, []);

     const renderModal = () => {
         return (
            <Modal title = "Details" isLoading={isLoading} state={isModalOpen?"is-active":""} onCancelClick={(e)=>{setModalOpen(false);setLoading(false);}} 
            onSubmitClick={()=>{
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                  submitForm(token);
              });
            });
          }}>
              <Content element="ul" >
                 <Content element="li"> <strong> NRIC/FIN/Passport: </strong>{nric.value}</Content>
             </Content>
         </Modal>
         )
     }


    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
          const isScriptExist = document.getElementById(id);
       
          if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
              if (callback) callback();
            };
            document.body.appendChild(script);
          }
       
          if (isScriptExist && callback) callback();
        }
       
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
          console.log("Script loaded!");
        });
      }, []);


    const submitForm = (token) => {
        setLoading(true);
        var formData = {};
        formData.form_id = "psp-patient-form";
        formData.form_submission_date = new Date().toISOString();// "2020-11-25T16:46:43.647Z";
        formData.form_response = {};
        formData["g-recaptcha-response"]= token;
        formData.form_response.patient_id = nric.value;
        formData.psp_doctor_submission_id = pspDoctorSubmissionId;
        formData.form_response.patient_date = moment().format('LLLL');
        Api.submitForm(formData).then((response)=>{
            if(response.status === 204){
              history.push("/thank_you_psp");
            }else{
                setModalOpen(false);
            }
        }).catch((e)=> {
            setLoading(false);
            setModalOpen(false);
            toast({
                message: '<h1>Something went wrong, Please try again later</h1>',
                type: 'is-danger',
                dismissible: true,
                position: "top-center",
                closeOnClick: true
              })
        });
     }


    return (
    <Columns> 
        <Content element="div" className="is-two-fifths hero is-danger">
           <Section className="sticky">
               <HeroBody > 
                   <Figure src="https://i.imgur.com/IlOJRNc.png"></Figure>
                    <Title className="is-size-3 is-spaced has-text-white-ter has-text-centered">
                    Roche PSP
                    <br/>
                    <Content element="span"> Patient Enrollment Form</Content>
                    </Title>
                    <br/>
                    <SubTitle className="has-text-centered is-5 has-text-white-ter">
                        <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faClock} style={{marginRight:10}} />
                        </Content> 
                        <b>5 min</b> estimated time to complete
                </SubTitle>
                <SubTitle className="is-5 has-text-white-ter"><br/>
                    <SubTitle className="is-5 has-text-weight-bold">Instructions</SubTitle>
                    <SubTitle className=" is-6 has-text-white-ter">
                        <u>Eligibility criteria:</u> <br/><br/>
                        - Roche PSP is open to patients who are receiving treatment in a Singapore private healthcare institution<br/><br/>
                        - Patients are prescribed with selected Roche treatment/diagnostic listed below for indications approved by Singapore Health Sciences Authority
                    </SubTitle>
                </SubTitle>                    
               </HeroBody>
           </Section>
        </Content>
        <Content element="div" className="is-paddingless">
           <Section>
           <HeroBody > 
            <Container className="is-fluid">
              <Divider> 
                  <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faUser} style={{marginRight:10}} />
                   </Content>  Patient Consent
              </Divider>
              <FormInput type="text" state={nric.error.length>0 ? "danger" : ""} errorText={nric.error} maxLength="4" className="input" size="medium" placeholder="Enter Last 4 digits" labelText="Patient Partial NRIC/FIN/Passport (Last 4 digits)" value={nric.value} onChange={(e) => setNric({value:e.target.value.toUpperCase(),error:""})}/>
              <Field>
                <Content element="p" >I understand, accept and acknowledge that this is a digital enrolment form and my wet signature is not required. I understand, accept and acknowledge that by submitting this form, I give my consent to Zuellig Pharma SG Solutions Pte Ltd (hereinafter ”ZP”), Roche Singapore Pte Ltd and its affiliates (hereinafter “Roche”) to collect, use, store and disclose my personal data for participating in the RochePSP program, and to contact me for any purpose of the program. I give my consent to ZP and Roche to disclose my personal data to third parties assigned to conduct audits of the program and to provide required deliverables for the program (including set-up and maintenance of the Roche PSP portal). I also consent to my treating physician named below to provide necessary updates to ZP and Roche for purpose of confirming my continued enrolment in the program. I hereby declare that the information provided in this form is accurate and to the best of my knowledge. I understand that any incomplete or inaccurate information may result in a delay or rejection of my enrolment into Roche PSP. I further understand that my application for enrolment to the program does not guarantee my eligibility for assistance; and the program may be terminated any time.</Content>
              </Field>
              <br/>
              <Button className="is-link" width="fullwidth" size="large" type="submit" onClick={(e)=>{
                  if(!validateForm()){
                    setModalOpen(true);
              }
              }}>Submit</Button>
            </Container>
            </HeroBody>
           </Section>
           <Footer className="has-text-centered"> 
               <Container>
                   <Content element="p" >
                        <Content element="strong" className="has-text-weight-semibold">
                            <a href="https://www.roche.com.sg">Roche Singapore</a>
                        </Content>
                   </Content>
                   <Content element="p" className="">
                        <Content element="small">
                            Powered by <a href="https://www.botmd.io">Bot MD</a>
                         </Content>
                   </Content>
               
                <Figure src="https://i.imgur.com/l2QQZuE.png"></Figure>
               </Container>
           </Footer>
           {renderModal()}
        </Content>
    </Columns>
    );
}

export default PSPPatientForm;
