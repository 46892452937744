import './App.scss';
import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import  AriseDoctorForm from "./forms/AriseDoctorEnrollmentForm";
import  PSPDoctorForm  from "./forms/PSPDoctorForm";
import  PSPPatientForm  from "./forms/PSPPatientForm";
import  ArisePatientForm  from "./forms/ArisePatientForm";
import { createBrowserHistory } from "history";
import ThankYouPage from './thank_you_arise';
import ThankYouDoctorPage from './thank_you_doctor_arise';
import AriseDoctorFormNonFmi from './forms/AriseDoctorNonFmi';
import ThankYouPSPDoctorPage from './thank_you_doctor_psp';
import ThankYouPSPPage from './thank_you_psp';

function App() {
  
  return (
    <div className="App">
     <Router history={createBrowserHistory()}>
        <Switch>
            <Route path="/arise_patient_form" render={() => <ArisePatientForm />}/>
            <Route path="/psp_patient_form" render={() => <PSPPatientForm />} />
            <Route path="/psp_doctor_form" render={() => <PSPDoctorForm />} />
            <Route path="/arise_doctor_form" render={() => <AriseDoctorForm />} />
            <Route path="/arise_doctor_form_nonfmi" render={() => <AriseDoctorFormNonFmi />} />
            <Route path="/thank_you_arise" render={() => <ThankYouPage />} />
            <Route path="/thank_you_doctor_arise" render={() => <ThankYouDoctorPage/>} />
            <Route path="/thank_you_psp_doctor" render={() => <ThankYouPSPDoctorPage/>} />
            <Route path="/thank_you_psp" render={() => <ThankYouPSPPage/>} />
          </Switch>
     </Router>
    </div>
  );
}

export default App;
