import axios from "axios";
import { HIPPOCRATES_URL } from "../config";

class Api {
    init() {     
          this.setupClient();
      }
    
      setupClient() {
        this.client = axios.create({
          baseURL: HIPPOCRATES_URL,
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.client.interceptors.response.use((res) => {
          return res;
        });
      }
    

    submitForm(formData) {
        return new Promise(async (resolve, reject) => {
        try {
            const resp = await this.client.post("/onform/submission_webhook", formData);
            resolve(resp);
        } catch (e) {
            reject(e);
        }
    });
  }
}

export default new Api();
