import { Columns, Content, Figure, Title, SubTitle, Modal, FormInput, Divider, Button, Field, Container, Footer, Section, HeroBody } from "botero"
import React, { useState, useEffect, useRef } from "react";
import { faClock, faUser } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../App.scss";
import Api from "../services/FormSubmissionApi";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { toast } from 'bulma-toast'
import { SITE_KEY } from "../config";
import { validateEmail } from "../utils";

const ArisePatientForm = () => {
    const params = new URLSearchParams(window.location.search);
    const [email, setEmail] = useState({value: "" ,error:""});
    const [phoneNumber, setPhoneNumber] = useState({value:"+65 ",error:""});
    const [caregiverName, setCaregiverName] = useState({value: "" ,error:""});
    const [nric, setNric] = useState({value: "" ,error:""});
    const history = useHistory();
    const ariseDoctorSubmissionId = params.get("doctorSubmissionId");
    const [isModalOpen,setModalOpen] = useState(false);
    const [isLoading,setLoading] = useState(false);
    const topRef = useRef(null);

    Api.init();

    const validateForm = () => {
        var error = false
        if(nric.value.length === 0){
            setNric({error:"NRIC/FIN is required", value:nric.value});
            error = true;
        }else if(nric.value.length < 4){
            setNric({error:"NRIC/FIN requires the last 4 digits", value:nric.value});
            error = true;
        }
        if(email.value.length > 0 && !validateEmail(email.value)){
            setEmail({error:"Email is not valid", value:email.value})
            error = true
        }

        scrollToTop();
        return error;
    }

    const scrollToTop = () => { topRef.current.scrollIntoView()};

    const renderModal = () => {
        return (
        <Modal title = "Confirm Details" isLoading={isLoading} state={isModalOpen?"is-active":""} onCancelClick={(e)=>{setModalOpen(false);setLoading(false);}}
            onSubmitClick={()=>{
                window.grecaptcha.ready(() => {
                 window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                     submitForm(token);
                 });
               });
             }}>
            <Content element="ul" >
                <Content element="li"> <strong> NRIC/FIN: </strong>{nric.value}</Content>
                <Content element="li"> <strong> Caregiver Name: </strong> {caregiverName.value? caregiverName.value : "Not Specified" }</Content>
                <Content element="li"> <strong> Alternate Contact: </strong> {phoneNumber.value.length > 4 ? phoneNumber.value : "Not Specified" }</Content>
                <Content element="li"> <strong> Email: </strong> {email.value? email.value : "Not Specified"}</Content>
            </Content>
        </Modal>
        )
    }


    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
          const isScriptExist = document.getElementById(id);
       
          if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
              if (callback) callback();
            };
            document.body.appendChild(script);
          }
       
          if (isScriptExist && callback) callback();
        }
       
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
          console.log("Script loaded!");
        });
      }, []);

    const submitForm = (token) => {
        setLoading(true);
        var formData = {};
        formData.arise_doctor_submission_id = ariseDoctorSubmissionId;
        formData.form_id = "arise-patient-form";
        formData["g-recaptcha-response"]= token;
        formData.form_submission_date = new Date().toISOString();
        formData.form_response = {
          patient_id: nric.value,
          caregiver_name: caregiverName.value,
          alternate_contact: phoneNumber.value.length < 4? "" : phoneNumber.value,
          patient_email: email.value,
          patient_date: moment().format('LLLL')
        };
        Api.submitForm(formData).then((response)=>{
            if(response.status === 204){
              history.push("/thank_you_arise");
            }else{
                setModalOpen(false);
            }
        }).catch((e)=> {
            setLoading(false);
            setModalOpen(false);
            toast({
                message: '<h1>Something went wrong, Please try again later</h1>',
                type: 'is-danger',
                dismissible: true,
                position: "top-center",
                closeOnClick: true
              })
        });
     }


    useEffect(() => {
        document.title = "Arise Patient Form"
     }, []);

    return (
    <Columns> 
        <Content element="div" className="is-two-fifths hero is-danger">
           <Section className="sticky">
               <HeroBody > 
                   <Figure src="https://i.imgur.com/IlOJRNc.png"></Figure>
                    <Title className="is-size-3 is-spaced has-text-white-ter has-text-centered">
                    ARISE Patient
                    <br/>
                    <Content element="span"> Enrollment Form</Content>
                    </Title>
                    <br/>
                    <SubTitle className="has-text-centered is-5 has-text-white-ter">
                        <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faClock} style={{marginRight:10}} />
                        </Content> 
                        <b>5 min</b> estimated time to complete
                </SubTitle>
                <SubTitle className="is-5 has-text-white-ter"><br/>
                    <SubTitle className="is-5 has-text-weight-bold">Instructions</SubTitle>
                    <SubTitle className=" is-6 has-text-white-ter">
                        <u>Eligibility criteria:</u> <br/><br/>
                        - ARISE is open to patients who are receiving treatment in a Singapore public healthcare institution<br/><br/>
                        - Patients are prescribed with selected Roche treatment/diagnostic listed below for indications approved by Singapore Health Sciences Authority
                    </SubTitle>
                </SubTitle>                    
               </HeroBody>
           </Section>
        </Content>
        <Content element="div" className="is-paddingless">
           <Section >
           <HeroBody > 
            <Container className="is-fluid">
              <Divider> 
                  <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faUser} style={{marginRight:5}} />
                   </Content>  Patient/Caregiver info 
              </Divider>
              <div ref={topRef}/>
              <FormInput type="text" className="input" size="medium" state={nric.error.length>0 ? "danger" : ""} errorText={nric.error} maxLength="4" placeholder="Enter last 4 digits" labelText="Patient NRIC/FIN(Last 4 digits)" value={nric.value} onChange={(e) => setNric({value:e.target.value.toUpperCase(),error:""})}/>
              <FormInput type="text" className="input" size="medium" state={caregiverName.error.length>0 ? "danger" : ""} errorText={caregiverName.error} placeholder="Enter Caregiver name" labelText="Caregiver Name" value={caregiverName.value} onChange={(e) => setCaregiverName({value:e.target.value,error:""})}/>
              <FormInput iconPosition="left" icon="phone" type="tel"  state={phoneNumber.error.length>0 ? "danger" : ""} errorText={phoneNumber.error} className="input" size="medium" placeholder="+65" labelText="Alternate Contact Number" value={phoneNumber.value} onChange={(e) => setPhoneNumber({value:e.target.value,error:""})}/>
              <FormInput iconPosition="left" icon="email" type="email" state={email.error.length>0 ? "danger" : ""} errorText={email.error} className="input" size="medium" placeholder="Enter Email" labelText="Email" value={email.value} onChange={(e) => setEmail({value:e.target.value,error:""})}/>
              <Field>
                <Content element="p" >I understand, accept and acknowledge that this is a digital enrolment form and my wet signature is not required. I understand, accept and acknowledge that by submitting this form, I give my consent to BioQuest Solutions (hereinafter ”BQ”), 5 Health Pte Ltd (hereinafter “Bot MD”), Roche Singapore Pte Ltd and its affiliates (hereinafter “Roche”) to collect, use, store and disclose my personal data for participating in the ARiSE programme, and to contact me for any purpose of the programme. I give my consent to BQ, Bot MD and Roche to disclose this information to third parties assigned to conduct audits of the programme. I hereby declare that the information provided in this form is accurate and to the best of my knowledge. I understand that any incomplete or inaccurate information may result in a delay or rejection of my enrolment into the ARiSE programme. I further understand that my application to the programme does not guarantee my eligibility for financial assistance; and the programme may be terminated any time.</Content>
              </Field>
              <br/>
              <Button className="is-link" width="fullwidth" size="large" type="submit" onClick={(e)=>{
                  if(!validateForm()){
                      setModalOpen(true);
                }
              }}>Submit</Button>
            </Container>
            </HeroBody>
           </Section>
           <Footer className="has-text-centered"> 
               <Container>
                   <Content element="p" >
                        <Content element="strong" className="has-text-weight-semibold">
                            <a href="https://www.roche.com.sg">Roche Singapore</a>
                        </Content>
                   </Content>
                   <Content element="p" className="">
                        <Content element="small">
                            Forms powered by <a href="https://www.botmd.io">Bot MD</a>
                         </Content>
                   </Content>
               
                <Figure src="https://i.imgur.com/l2QQZuE.png"></Figure>
               </Container>
           </Footer>
           {renderModal()}
        </Content>
    </Columns>
    );
}

export default ArisePatientForm;
