import {Columns, Content, Figure, Title, SubTitle, Modal, FormPhoneInput,FormInput, FormSelect, Divider, Label, Button, Field, Container, Footer, Section, HeroBody, Input} from "botero"
import { faClock, faUserAlt, faPrescription } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useState, useEffect, useRef} from "react";
import "../App.scss";
import Api from "../services/FormSubmissionApi";
import cuid from "cuid";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { toast } from 'bulma-toast'
import { SITE_KEY } from "../config";
import { validateEmail } from "../utils";

const PSPDoctorForm = () => {
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);

    const [patient, setPatient] = useState({name:params.get("patientName")?params.get("patientName"):"",error:""});
    const [email, setEmail] = useState({value: "" ,error:""});
    const [phoneNumber, setPhoneNumber] = useState({value:"",error:"",code:"+65"});
    const [alternatePhoneNumber, setAlternatePhoneNumber] = useState({value:"",error:"",code:"+65"});
    const [patientDoB, setPatientDob] = useState({value: "" ,error:""});
    const [caregiverName, setCaregiverName] = useState({value: "" ,error:""});
    const [nationality, setNationality] = useState({value: "" ,error:""});
    const [nric, setNric] = useState({value: "" ,error:""});
    const [treatment, setTreatment] = useState({value: "" ,error:""});
    const [treatmentIndication, setTreatmentIndication] = useState({value: "" ,error:""});
    const [clinicalAdminEmail, setClinicalAdminEmail] = useState({value: "" ,error:""});
    const [physicianEmail, setPhysicianEmail] = useState({value: params.get("doctorEmail") ? params.get("doctorEmail") : "" ,error:""});
    const [hasConsented, setConsent] = useState(true);
    const [physicianName, setPhysicianName] = useState({value:params.get("doctorName")?params.get("doctorName"):"",error:""});
    const [permanentResident, setPermanentResident] = useState({value: "" ,error:""});
    const [isLoading,setLoading] = useState(false);
    const [hasNoPhone, setHasNoPhone] = useState(false);
    const [isNotified, setNotified] = useState(true);
    const [avastinDose, setAvastinDose] = useState({value: "" ,error:""});
    const [patientWeight, setPatientWeight] = useState({value: "" ,error:""});
    const [treatmentPhase, setTreatmentPhase] = useState({value: "" ,error:""});

    const [treamtmentDataMap, setTreatmentDataMap] = useState([]);
    const submissionId = cuid(); 
    const [isModalOpen,setModalOpen] = useState(false);

    const topRef = useRef(null);
    const midRef = useRef(null);

    Api.init();

    useEffect(() => {
        document.title = "PSP Enrollment Form"
     }, []);

     useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
          const isScriptExist = document.getElementById(id);
       
          if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
              if (callback) callback();
            };
            document.body.appendChild(script);
          }
       
          if (isScriptExist && callback) callback();
        }
       
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
          console.log("Script loaded!");
        });
      }, []);


    const submitForm = (token) => {
        setLoading(true);
          var formData = {};
            formData.psp_doctor_submission_id = submissionId;
            formData.form_id = "psp-doctor-form";
            formData["g-recaptcha-response"]= token;
            formData.form_submission_date = new Date().toISOString();
            formData.form_response = {
              patient_id: nric.value,
              permanent_resident: permanentResident.value,
              patient_name: patient.name,
              patient_dob: patientDoB.value,
              patient_contact: phoneNumber.code +""+ phoneNumber.value,
              patient_email: email.value,
              treatment: treatment.value,
              alternate_contact: alternatePhoneNumber.code + "" + alternatePhoneNumber.value,
              indication: treatmentIndication.value,
              doctor_name: physicianName.value,
              doctor_email: physicianEmail.value,
              clinical_admin_email: clinicalAdminEmail.value,
              nationality: permanentResident.value === "Yes"? "Singaporean" : nationality.value,
              caregiver_name: caregiverName.value,
              consent: hasConsented,
              avastin_dose: avastinDose.value,
              treatment_phase: treatmentPhase.value,
              has_mobile_phone: !hasNoPhone,
              patient_weight: patientWeight.value,
              notify_patient_enrollment: isNotified,
              doctor_date: moment().format('LLLL')
            };
                  
        Api.submitForm(formData).then((response)=>{
          setLoading(false);
            if(response.status === 204){
              history.push("/thank_you_psp_doctor");
            }else{
                setModalOpen(false);
            }
        }).catch((e)=> {
            setLoading(false);
            setModalOpen(false);
            toast({
                message: '<h1> Something went wrong, Please try again later </h1>',
                type: 'is-danger',
                dismissible: true,
                position: "top-center",
                closeOnClick: true
              })
        });
     }

    const validateForm = () => {
        var error = false
        if(patient.name.length === 0){
            setPatient({error:"Patient Name is required",name:patient.name});
            error = true;
            scrollToTop();
        }if(!hasNoPhone && phoneNumber.value.length < 5){
            setPhoneNumber({error:"Patient Mobile is required OR Select checkbox if Patient has no mobile",value:"",code:phoneNumber.code});
            error = true;
            scrollToTop();
        }if(nric.value.length === 0){
            setNric({error:"NRIC/FIN/Passport is required",value:""});
            error = true;
            scrollToTop();
        }if(clinicalAdminEmail.value.length === 0){
            setClinicalAdminEmail({error:"Clinical admin is required",value:""});
            error = true;
        }if(patientDoB.value.length === 0){
            setPatientDob({error:"Patient DoB is required",value:""});
            error = true;
        }if(physicianEmail.value.length === 0){
            setPhysicianEmail({error:"Physician email is required",value:""});
            error = true;
        }if((permanentResident.value === "No") && nationality.value.length === 0){
            setNationality({error:"Nationality is required",value:""});
            error = true;
        }
        if((permanentResident.value === "Select Option") || permanentResident.value.length === 0){
            setPermanentResident({error:"Singaporean/PR is required",value:""});
            error = true;
        }
        if((treatment.value === 'Tecentriq® + Avastin®') && patientWeight.value.length === 0){
            setPatientWeight({error:"Patient weight is required",value:""});
            error = true;
        }
        if((treatment.value === 'Tecentriq® + Avastin®') && avastinDose.value.length === 0){
            setAvastinDose({error:"Avastin Dose is required",value:""});
            error = true;
        }
        if(clinicalAdminEmail.value === ""){
            setClinicalAdminEmail({error:"Clinical Admin Email is required",value:""});
            error = true;
        }else if(clinicalAdminEmail.value.length > 0 && !validateEmail(clinicalAdminEmail.value)){
            setClinicalAdminEmail({error:"Email is not valid", value:email.value})
            error = true
        }

        if(treatment.value === 'Tecentriq® + Avastin®' && treatmentIndication.value === "NSCLC *EGFR/ALK+" && (treatmentPhase.value === "" || treatmentPhase.value === 'Select Treatment Phase')){
            setTreatmentPhase({error:"Treatment Phase is required",value:""});
            error = true;
            scrollToMid();
        }   
        if(treatment.value.length === 0 || treatment.value === 'Select Treatment'){
            setTreatment({error:"Treatment is required",value:""});
            error = true;
            scrollToMid();
        }else if(treatmentIndication.value.length === 0 || treatmentIndication.value === 'Select Indication'){
            setTreatmentIndication({error:"Indication is required",value:""});
            error = true;
            scrollToMid();
        }
        if(email.value.length > 0 && !validateEmail(email.value)){
            setEmail({error:"Email is not valid", value:email.value})
            error = true
            scrollToMid()
        }
        return error;
    }

    const scrollToTop = () => { topRef.current.scrollIntoView()};

    const scrollToMid = () => { midRef.current.scrollIntoView()};


    useEffect(()=>{
        if(treatment.value === 'Alecensa®'){
            setTreatmentDataMap(["Select Indication","ALK+ NSCLC 1st Line","ALK+ NSCLC 2nd Line"]);
        }else if(treatment.value === 'Gazyva®'){
            setTreatmentDataMap(["Select Indication","Follicular Lymphoma (1st Line)","Follicular Lymphoma (2nd Line)","Chronic Lymphocytic Leukemia (1st Line)"]);
        }else if(treatment.value === 'Tecentriq®'){
            setTreatmentDataMap(["Select Indication","ES SCLC 1st Line"]);
        }else if(treatment.value === 'Tecentriq® + Avastin®'){
            setTreatmentDataMap(["Select Indication","HCC 1st Line","NSCLC 1st Line (Non-Squamous)","NSCLC *EGFR/ALK+"]);
        }else if(treatment.value === 'Perjeta®'){
            setTreatmentDataMap(["Select Indication","Neo-Adjuvant Treatment","Adjuvant Treatment","Metastatic Breast Cancer (1st Line)"]);
        }else{
            setTreatmentDataMap([]);
        }
    },[treatment]);

    const renderModal= () => {
        return (
        <Modal title = "Confirm Enrollment Details" isLoading={isLoading} state={isModalOpen?"is-active":""} onCancelClick={(e)=>{setModalOpen(false); setLoading(false); scrollToTop()}} 
        onSubmitClick={()=>{
         window.grecaptcha.ready(() => {
          window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
              submitForm(token);
          });
        });
      }}>
        <SubTitle className="is-4" > <strong> Patient Details </strong></SubTitle>
         <Content element="ul" >
             <Content element="li"> <strong> NRIC/FIN/Passport: </strong>{nric.value}</Content>
             <Content element="li"> <strong> Patient Name: </strong> {patient.name}</Content>
             <Content element="li"> <strong> Patient Contact: </strong> {phoneNumber.value? `${phoneNumber.code}-${phoneNumber.value}` : "NA (Hardcopy)"}</Content>
             <Content element="li"> <strong> Singaporean/PR: </strong> { permanentResident.value}</Content>
             <Content element="li" visibility={permanentResident.value !== "Yes" ? "" : "hidden"}> <strong> Nationality: </strong> {nationality.value}</Content>
             <Content element="li" visibility={caregiverName.value ? "" : "hidden"}> <strong> Caregiver Name: </strong> { caregiverName.value}</Content>
             <Content element="li" visibility={alternatePhoneNumber.value ? "" : "hidden"}> <strong> Alternate contact: </strong> { alternatePhoneNumber.code +"-"+alternatePhoneNumber.value}</Content>
             <Content element="li" visibility={email.value ? "" : "hidden"}> <strong> Patient/Caregiver email: </strong> { email.value}</Content>
         </Content>
         <br/>
         <SubTitle className="is-4"> <strong> Treatment Info </strong></SubTitle>
         <Content element="ul">
             <Content element="li"> <strong> Treatment: </strong> {treatment.value}</Content>
             <Content element="li"> <strong> Treatment Indication: </strong> {treatmentIndication.value}</Content>
             <Content element="li" visibility={treatment.value === 'Tecentriq® + Avastin®' ? "" : "hidden"}> <strong> Avastin Dose: </strong> {avastinDose.value ? avastinDose.value + " mg/kg" : "NA"}</Content>
             <Content element="li" visibility={treatment.value === 'Tecentriq® + Avastin®' ? "" : "hidden"}> <strong> Patient Weight: </strong> {patientWeight.value ? patientWeight.value + " kg": "NA"}</Content>
             <Content element="li" visibility={treatmentIndication.value === 'NSCLC *EGFR/ALK+' ? "" : "hidden"}> <strong> Treatment Phase: </strong> {treatmentPhase.value ? treatmentPhase.value : "NA"}</Content>
         </Content>
         <br/>
         <SubTitle className="is-4">  <strong> Physician Details </strong></SubTitle>
         <Content element="ul">
             <Content element="li"> <strong> Physician Name: </strong>{physicianName.value}</Content>
             <Content element="li"> <strong> Physician Email: </strong> {physicianEmail.value}</Content>
             <Content element="li"> <strong> Clinical Admin Email: </strong>{clinicalAdminEmail.value}</Content>
         </Content>
     </Modal>
     );
    };


    return (
    <Columns> 
        <Content element="div" className="is-two-fifths hero is-link">
           <Section className="sticky">
               <HeroBody > 
                   <Figure src="https://i.imgur.com/IlOJRNc.png"></Figure>
                    <Title className="is-size-3 is-spaced has-text-white-ter has-text-centered">
                    Roche PSP Enrollment Form
                    <br/>
                    <Content element="span"> (For Physician)</Content>
                    </Title>
                    <br/>
                    <SubTitle className="has-text-centered is-5 has-text-white-ter">
                        <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faClock} style={{marginRight:10}} />
                        </Content> 
                        <b>5 min</b> estimated time to complete
                </SubTitle>
                <SubTitle className="is-5 has-text-white-ter"><br/>
                    <SubTitle className="is-5 has-text-weight-bold">Instructions</SubTitle>
                    <SubTitle className=" is-6 has-text-white-ter">
                        <u>Eligibility criteria:</u> <br/><br/>
                        - Roche PSP is open to patients who are receiving treatment in a Singapore private healthcare institution<br/><br/>
                        - Patients are prescribed with selected Roche treatment/diagnostic listed below for indications approved by Singapore Health Sciences Authority
                    </SubTitle>
                </SubTitle>                    
               </HeroBody>
           </Section>
        </Content>
        <Content element="div" className="is-paddingless">
           <Section >
           <HeroBody > 
            <Container className="is-fluid">
              <Divider> 
                  <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faUserAlt} style={{marginRight:10}} />
                   </Content>  Patient Details 
              </Divider>
              <div ref={topRef}/>
              <FormInput type="text" className="input" size="medium"  placeholder="Enter Patient Name" labelText="Patient Name" state={patient.error.length>0 ? "danger" : ""} errorText={patient.error} value={patient.name} onChange={(e) => setPatient({name:e.target.value,error:""})}/>
              <FormInput type="text" className="input" maxlength="4" state={nric.error.length>0 ? "danger" : ""} errorText={nric.error} size="medium" placeholder="Enter Last 4 digits" labelText="Patient Partial NRIC/FIN/Passport(Last 4 digits)" value={nric.value} onChange={(e) => setNric({value:e.target.value.toUpperCase(),error:""})}/>
              <FormSelect  state={permanentResident.error.length>0 ? "danger" : ""} errorText={permanentResident.error}  labelText="Is your patient Singaporean/PR?" size="medium" 
                    onChange={((e) => {
                        setPermanentResident({value:e.target.value,error:""});
                        if(e.target.value !== 'No'){
                            setNationality({value:"",error:""}) 
                        }
                    })}>
                    <option className="selected"> Select Option</option>
                    <option>Yes</option>
                    <option>No</option>
              </FormSelect>

              <FormInput type="text" visibility={permanentResident.value === "No" ? "": "hidden"} className="input" state={nationality.error.length>0 ? "danger" : ""} errorText={nationality.error} size="medium" placeholder="Enter Nationality" labelText="Nationality" value={nationality.value} onChange={(e) => setNationality({value:e.target.value,error:""})}/>

              <FormInput type="tel" className="input" maxLength="10" size="medium" state={patientDoB.error.length>0 ? "danger" : ""} errorText={patientDoB.error} placeholder="Enter DD/MM/YY" labelText="Patient Date of Birth" value={patientDoB.value} onChange={(e) => {setPatientDob({value:e.target.value,error:""})}}/>
              <FormPhoneInput phoneValue={phoneNumber.code} onPhoneCodeChange={(e) =>{setPhoneNumber({code:e.target.value,value:phoneNumber.value,error:""});}} type="tel" className="input" size="medium"  disabled={hasNoPhone} state={phoneNumber.error.length>0 ? "danger" : ""} errorText={phoneNumber.error} placeholder="Enter Phone Number" labelText="Patient/Caregiver Mobile" value={phoneNumber.value} onChange={(e) =>{setPhoneNumber({value:e.target.value,error:"",code: phoneNumber.code});}}/>
              <Label type="checkbox" >
                <Input type="checkbox" style={{marginRight:10}}  onChange={(e)=>{setHasNoPhone(!hasNoPhone); if(!hasNoPhone) {setPhoneNumber({value:"",error:"",code:phoneNumber.code})}}}/>
                Select this if your patient or their caregiver DOES NOT have a mobile phone and needs a hard copy to sign
              </Label>
              <FormInput type="text" className="input" size="medium" state={caregiverName.error.length>0 ? "danger" : ""} errorText={caregiverName.error} placeholder="Enter Caregiver here" labelText="Caregiver Name" value={caregiverName.value} onChange={(e) => setCaregiverName({value:e.target.value,error:""})}/>
              
              <FormPhoneInput phoneValue={alternatePhoneNumber.code} onPhoneCodeChange={(e) =>{setAlternatePhoneNumber({code:e.target.value,value:alternatePhoneNumber.value,error:""})}} type="tel" className="input" size="medium" placeholder="Enter Phone Number" state={alternatePhoneNumber.error.length>0 ? "danger" : ""} errorText={alternatePhoneNumber.error} labelText="Alternate Contact Number" value={alternatePhoneNumber.value} onChange={(e) => setAlternatePhoneNumber({value:e.target.value,error:"",code:alternatePhoneNumber.code})}/>
             
              <FormInput type="email" className="input" size="medium" state={email.error.length>0 ? "danger" : ""} errorText={email.error} placeholder="Enter Email" labelText="Email" value={email.value} onChange={(e) => setEmail({value:e.target.value,error:""})}/>
            
             
              <Divider> 
                  <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faPrescription} style={{marginRight:10}} />
                   </Content> Treatment Info 
              </Divider>
              <div ref={midRef}/>

              <FormSelect state={treatment.error.length>0 ? "danger" : ""} errorText={treatment.error}  labelText="Treatment" size="medium" onChange={(e)=> {
                  setTreatment({value:e.target.value,error:""}); 
                  setTreatmentPhase({error:"",value:""});
                  setAvastinDose({error:"",value:""});
                  setPatientWeight({error:"",value:""});
                  setTreatmentIndication({error:"",value:""})
                }}>
                    <option className="selected"> Select Treatment</option>
                    <option value="Alecensa®">Alecensa®</option>
                    <option value="Gazyva®">Gazyva®</option>
                    <option value="Tecentriq®">Tecentriq®</option>
                    <option value="Perjeta®"> Perjeta®</option>
                    <option value="Tecentriq® + Avastin®">Tecentriq® + Avastin®</option>
              </FormSelect>

             {console.log(treatmentIndication.value)}
              <FormSelect state={treatmentIndication.error.length>0 ? "danger" : ""} value={treatmentIndication.value} errorText={treatmentIndication.error} labelText="Treatment Indication" size="medium" visibility={treamtmentDataMap.length>0 ? "": "hidden"} onChange={((e) => {
                  setTreatmentIndication({value:e.target.value,error:""})
                  if(e.target.value === 'Select Indication'){
                    setTreatmentPhase({error:"",value:""});
                    setAvastinDose({error:"",value:""});
                    setPatientWeight({error:"",value:""});
                }else if(e.target.value !== 'NSCLC *EGFR/ALK+'){
                    setTreatmentPhase({error:"",value:""});
                }
                })}>
                    {treamtmentDataMap.map((treatment,pos)=> <option value={treatment} selected={treatmentIndication.value === "" && pos === 0}>{treatment}</option>)}
              </FormSelect>

              <FormInput type="text" className="input" size="medium" state={avastinDose.error.length>0 ? "danger" : ""} errorText={avastinDose.error} placeholder="Enter dose" visibility={treatment.value === 'Tecentriq® + Avastin®' && (treatmentIndication.value !== "Select indication" || treatmentIndication.value !== "") ? "": "hidden"} labelText="Avastin® Dose (mg/kg)" value={avastinDose.value} onChange={(e) => setAvastinDose({value:e.target.value,error:""})}/>
              <FormInput type="text" className="input" size="medium" state={patientWeight.error.length>0 ? "danger" : ""} errorText={patientWeight.error} visibility={treatment.value === 'Tecentriq® + Avastin®' && (treatmentIndication.value !== "Select indication" || treatmentIndication.value !== "") ? "" : "hidden"} placeholder="Enter patient weight here" labelText="Patient Weight (kg)" value={patientWeight.value} onChange={(e) => setPatientWeight({value:e.target.value,error:""})}/>
              <FormSelect state={treatmentPhase.error.length>0 ? "danger" : ""} errorText={treatmentPhase.error} labelText="Treatment Phase" size="medium" visibility={treatment.value === 'Tecentriq® + Avastin®' && treatmentIndication.value === "NSCLC *EGFR/ALK+" ? "" : "hidden"} onChange={((e) => setTreatmentPhase({value:e.target.value,error:""}))}>
                    <option className="selected"> Select Treatment Phase</option>
                    <option>Induction</option>
                    <option>Maintenance</option>
              </FormSelect>

              <Divider> 
                  <Content element="span" className="icon">
                        <FontAwesomeIcon icon={faUserAlt} style={{marginRight:10}} />
                   </Content> Physician Details
              </Divider>

              <FormInput type="email" className="input" size="medium" state={clinicalAdminEmail.error.length>0 ? "danger" : ""} errorText={clinicalAdminEmail.error} placeholder="Enter Email" labelText="Clinic Admin's Email" value={clinicalAdminEmail.value} onChange={(e) => setClinicalAdminEmail({value:e.target.value,error:""})}/>

              <FormInput type="text" className="input" style={{color:"black"}} size="medium" disabled={true} placeholder="Enter Physicians name here" labelText="Prescribing Doctor's Name" value={physicianName.value} onChange={(e) => setPhysicianName({value:e.target.value,error:""})}/>
              <FormInput type="email" className="input" style={{color:"black"}} size="medium" disabled={true} placeholder="Enter Email" labelText="Prescribing Doctor's Email" value={physicianEmail.value} onChange={(e) => setPhysicianEmail({value:e.target.value,error:""})}/>
              <br/>

              <Content element="p">I give consent to be contacted by the Roche Local Safety Unit to obtain additional information on reported adverse event(s) experienced by the patient enrolled in ARiSE programme under my care.</Content>

              <br/>
              <Content element="div" className="field is-grouped">
                  <Button color="primary" buttonStyle={hasConsented? "" : "outlined"} style={{marginRight:10}} onClick={(e)=>{if(!hasConsented) setConsent(true)}}>
                      YES
                  </Button>
                  <Button color={hasConsented? "" : "secondary"} buttonStyle={hasConsented? "" : "outlined"} background={hasConsented? "" : "grey-light"} onClick={(e)=>{if(hasConsented) setConsent(false)}}>
                      NO
                  </Button>
              </Content>

              <Content element="p" classname="" type="checkbox" >
                <Input type="checkbox" style={{marginRight:10}} defaultChecked onChange={(()=>{setNotified(!isNotified)})}/>
                I WISH to be notified of the outcome of the patient's enrollment application
              </Content>
              
              <Field>
                  <p>”I understand, accept and acknowledge that this is a digital enrolment form and my wet signature is not required. By submitting this form, I confirm that the above-named patient's identity has been duly checked. I understand that the patient who is enrolled in the TECENTRIQ® induction phase should be re-enrolled for maintenance phase if the patient is suitable. I give my consent to Zuellig Pharma SG Solutions Pte Ltd (hereinafter ”ZP”), Roche Singapore Pte Ltd and its affiliates (hereinafter “Roche”) and 5 Health Pte Ltd (hereinafter “Bot MD”), to collect, use, store and disclose my personal data for any purpose of contacting me for the Roche PSP program. I give my consent to ZP, Bot MD and Roche to disclose this information to third parties assigned to conduct audits of the program and to provide required deliverables for the program.</p>
              </Field>
              <br/>
              <Button className="is-link is-fullwidth" size="large" type="submit" onClick={(e)=>{
                  if(!validateForm()){                
                    setModalOpen(true);
                }
              }}>Submit</Button>
            </Container>
            </HeroBody>
           </Section>
           <Footer className="has-text-centered"> 
               <Container>
                   <Content element="p" >
                        <Content element="strong" className="has-text-weight-semibold">
                            <a href="https://www.roche.com.sg">Roche Singapore</a>
                        </Content>
                   </Content>
                   <Content element="p" className="">
                        <Content element="small">
                            Powered by <a href="https://www.botmd.io">Bot MD</a>
                         </Content>
                   </Content>
               
                <Figure src="https://i.imgur.com/l2QQZuE.png"></Figure>
               </Container>
           </Footer>
            {renderModal()}
        </Content>
    </Columns>
    );
}

export default PSPDoctorForm;
