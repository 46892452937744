import {Label, Content, Figure, Title, SubTitle, Footer, Divider, Container, Section, HeroBody} from "botero"
import React, { useEffect } from "react";
import "./App.scss";

const ThankYouPSPDoctorPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Content element="div">
        <Section className="hero is-link">
        <HeroBody>
            <Container >
                <Figure src="https://i.imgur.com/IlOJRNc.png">
                </Figure>
                <Title className="has-text-centered">
                    Thank you for your PSP
                    <Content element="p"> Enrollment Submission! 😎🌟</Content>
                </Title><br/>
                <SubTitle className="has-text-centered">
                We will send you an email to confirm the submission of your enrollment form.
                </SubTitle>
    
                    <Content className="div">
                        <Divider >
                            <Label className="has-text-white" size="medium">
                                FOR MORE INFO</Label></Divider>
                    </Content>
                <SubTitle className="has-text-centered">
                    <Content element="b">Call 800-130-2011 (Toll Free)</Content>
                </SubTitle>
                <SubTitle className=" has-text-centered">
                    <Content element="b">Email: support@arise.sg</Content>
                </SubTitle>
                </Container>
            </HeroBody>
        </Section>
        <Footer className="has-text-centered"> 
        <Container>
            <Content element="p" >
                 <Content element="strong" className="has-text-weight-semibold">
                     <a href="https://www.roche.com.sg">Roche Singapore</a>
                 </Content>
            </Content>
            <Content element="p" className="">
                 <Content element="small">
                     Powered by <a href="https://www.botmd.io">Bot MD</a>
                  </Content>
            </Content>
        
         <Figure src="https://i.imgur.com/l2QQZuE.png"></Figure>
        </Container>
    </Footer>
    </Content>
        
    );
}

export default ThankYouPSPDoctorPage;
